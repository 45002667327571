import {endOfDay, startOfDay, subDays} from "date-fns";

const AppConstant = {
    pageMessage: 50,
    minLoad: 10,
    splitPageMessageTime: 2 * 60 * 60 * 1000, // milliseconds,
    timeRefreshToken: 2 * 60 * 60 * 1000, // milliseconds
    characterSplit: ","
}

export const PLATFORM = {
    website: "website",
    facebook: "facebook",
    zalo: "zalo",
    shopee: "shopee",
    tiktok: "tiktok",
    app: "app"
}

export const ConversationStatus = {
    normal: "normal",
    waiting: "waiting",
    processing: "processing",
    processed: "processed",
}

export const messageType = {
    text: "text",
    voice: "voice",
    emoji: "emoji",
    file: "file",
}

export const MESSAGE_TYPE = {
    text: "text",
    audio: "audio",
    video: "video",
    image: "image",
    file: "file",
}

export const initTime = {
    startDate: subDays(new Date(), 6),
    endDate: new Date()
}

export const CHART_DATETIME_PICKER_INIT = {
    from: startOfDay(subDays(new Date(), 6)),
    to: endOfDay(new Date())
}

export const DATETIME_PICKER_INIT = {
    from: startOfDay(new Date()),
    to: endOfDay(new Date())
}

export const roleMember = {
    owner: "owner",
    admin: "admin",
    chatAgent: "chatAgent",
    tester: "tester"
}

export const statusCustomer = {
    available: "available",
    unavailable: "unavailable",
    invisible: "invisible",
    away: "away",
    dnd: "dnd",
    eager: "eager"
}

export const statusMember = {
    accepted: "accepted",
    rejected: "rejected",
    pending: "pending",
    locked: "locked"
}

export const MESSAGEAGENT = {
    customer: "customer",
    account: "account",
    botAI: "botAI"
}

// seconds
export const PERIODIC_TIME = {
    oneDay: 24 * 60 * 60,
    sevenDays: 7 * 24 * 60 * 60,
    fifteenDays: 15 * 24 * 60 * 60,
    thirtyDays: 30 * 24 * 60 * 60,
    threeMonths: 90 * 24 * 60 * 60,
    oneYear: 365 * 24 * 60 * 60,
}

export const PLAN_TYPE = {
    periodic: "periodic",
    usage: "usage"
}

export const SOCKET_EVENT = {
    message: "message",
    customerMessage: "message/customer",
    accountMessage: "message/account",
    botMessage: "message/bot",
    botStatus: "status",
    controlBot: "control",
    readMessage: "read_message",
    chatDirect: "chat_direct",
    statusConversations: "status_conversations",
    handOverConversation: 'hand_over_conversation',
    requestHandOverConversation: 'request_hand_over_conversation',
    rejectHandOverConversation: 'reject_hand_over_conversation',
    FINISH_SESSION: 'FINISH_SESSION',
    PING: "PING",
    REQUEST_DIRECT_ACCOUNT: 'REQUEST_DIRECT_ACCOUNT',
    CHANGE_STATUS_CONVERSATION: 'CHANGE_STATUS_CONVERSATION',
    EXPIRED_REQUEST_DIRECT: 'EXPIRED_REQUEST_DIRECT',
    REQUEST_SUPPORT: 'REQUEST_SUPPORT',
}

export const SIZE_SCREEN = {
    sx: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    "2xl": 1536
}
export const ACCOUNT_ROLE = {
    user: "user",
    admin: "admin",
}

export const WINDOW_MESSAGE = {
    topUpSuccessfully: "topUpSuccessfully",
    topUpCancelled: "topUpCancelled"
}

export const APP_LANGUAGE = {
    vi: "vi",
    en: "en"
}

export const NOTIFICATION_PERMISSION_STATUS = {
    granted: "granted",
    denied: "denied",
    default: "default"
}

export const CONVERSATION_STATUS = {
    normal: "normal",
    waiting: "waiting",
    processing: "processing",
    processed: "processed",
}

export const APP_TIME = {
    debounce: 1000 // milliseconds
}

export const STATUS_INDEX = {
    ALL: 0,
    WAITING: 1,
    PROCESSING: 2,
    WAITING_AND_PROCESSING: 3
}

export const SOCKET_CONFIG = {
    ATTEMPT: 3,
    TIME_PING: 30 * 1000 // milliseconds
}

export const TOAST_CONFIG = {
    SOCKET_DISCONNECT_ID: 1000,
    RUN_OUT_OF_TOKEN_ID: 1001
}

export const TYPE_FILE_EXPORT = {
    EXCEL: 'excel',
    CSV: 'csv'
}

export const BOT_SYSTEM_STATUS = {
    OK: "OK",
    NOK: "NOK"
}

export const NUANCE = {
    POSITIVE: "POSITIVE",
    NEUTRAL: "NEUTRAL",
    NEGATIVE: "NEGATIVE"
}

export const CATEGORY = {
    PLAN: "PLAN",
    PROMOTION_AND_CARE: "PROMOTION_AND_CARE",
    PROFESSTIONAL: "PROFESSTIONAL",
    INTERNATION_ROAMING: "INTERNATION_ROAMING",
    PRODUCT_AND_SERVICE: "PRODUCT_AND_SERVICE",
    OTHER: "OTHER"
}
export const topicLink = process.env.REACT_APP_URL_SOCKET_IO.replace("/conversation", "")


export const WEBSOCKET_COMMAND = {
    ACCOUNT_LOGIN : "account_login",
    AGENT_MESSAGE :"agent_message",
    CUSTOMER_MESSAGE : "customer_message",
    BOT_MESSAGE : "bot_message",
    TAKE_CONTROL : "take_control",
    REQUEST_SUPPORT : "request_support",
    TRANSFER_SUPPORT : "transfer_support",
    BOT_MESSAGE_CHUNK : "bot_message_chunk",
    BOT_MESSAGE_COMPLETE : "bot_message_complete",
}

export const bankMap = {
    VCB: { bank: "NH TMCP Ngoai Thuong VN", bankName: "VietcomBank" },
    CTG: { bank: "NH TMCP Cong Thuong VN", bankName: "VietinBank" },
    TCB: { bank: "NH TMCP Ky Thuong VN", bankName: "Techcombank" },
    BIDV: { bank: "NH TMCP Dau Tu va Phat Trien VN", bankName: "BIDV" },
    VARB: { bank: "NH Nong Nghiep va Phat Trien Nong Thon Viet Nam", bankName: "AgriBank" },
    NCB: { bank: "Ngan hang Thuong Mai Co Phan Quoc Dan", bankName: "National Citizen Bank" },
    STB: { bank: "NH TMCP Sai Gon Thuong Tin", bankName: "Sacombank" },
    ACB: { bank: "NH TMCP A Chau", bankName: "ACB" },
    MB: { bank: "NH TMCP Quan Doi", bankName: "MBBank" },
    TPB: { bank: "NH TMCP Tien Phong", bankName: "TPBank" },
    SVB: { bank: "NH TNHH MTV Shinhan VN", bankName: "Shinhan Bank" },
    VIB: { bank: "NH TMCP Quoc Te VN", bankName: "VIB Bank" },
    VPB: { bank: "NH TMCP Viet Nam Thinh Vuong", bankName: "VPBank" },
    SHB: { bank: "NH TMCP Sai Gon Ha Noi", bankName: "SHB" },
    EIB: { bank: "NH TMCP Xuat Nhap khau VN", bankName: "Eximbank" },
    BVB: { bank: "NH TMCP Bao Viet", bankName: "BaoVietBank" },
    VCCB: { bank: "NH TMCP Ban Viet", bankName: "VietcapitalBank" },
    SCB: { bank: "NH TMCP Sai Gon", bankName: "SCB" },
    VRB: { bank: "NH Lien Doanh Viet Nga", bankName: "VietNam - Russia Bank" },
    ABB: { bank: "NH TMCP An Binh", bankName: "ABBank" },
    PVCB: { bank: "NH TMCP Dai Chung VN", bankName: "PVCombank" },
    OJB: { bank: "NH TM TNHH MTV Dai Duong", bankName: "OceanBank" },
    NAB: { bank: "NH TMCP Nam A", bankName: "NamA bank" },
    HDB: { bank: "NH TMCP Phat Trien TP HCM", bankName: "HDBank" },
    VB: { bank: "NH TMCP Viet Nam Thuong Tin", bankName: "VietBank" },
    CFC: { bank: "Công ty Tài chính Cổ Phần Tín Việt", bankName: "VietCredit" },
    PBVN: { bank: "NH TNHH MTV Public VN", bankName: "Public bank" },
    HLB: { bank: "NH TNHH MTV Hongleong VN", bankName: "Hongleong Bank" },
    PGB: { bank: "NH TMCP Xang Dau Petrolimex", bankName: "PG Bank" },
    COB: { bank: "NH Hop Tac", bankName: "Co.op Bank" },
    CIMB: { bank: "NH TNHH MTV CIMB Viet Nam", bankName: "CIMB" },
    IVB: { bank: "NH TNHH Indovina", bankName: "Indovina" },
    DAB: { bank: "NH TMCP Dong A", bankName: "DongABank" },
    GPB: { bank: "NH TM TNHH MTV Dau Khi Toan Cau", bankName: "GPBank" },
    NASB: { bank: "NH TMCP Bac A", bankName: "BacABank" },
    VAB: { bank: "NH TMCP Viet A", bankName: "VietABank" },
    SGB: { bank: "NH TMCP Sai Gon Cong Thuong", bankName: "SaigonBank" },
    MSB: { bank: "NH TMCP Hang Hai VN", bankName: "Maritime Bank" },
    LPB: { bank: "NH TMCP Buu Dien Lien Viet", bankName: "LienVietPostBank" },
    KLB: { bank: "NH TMCP Kien Long", bankName: "KienLongBank" },
    IBKHN: { bank: "NH Cong Nghiep Han Quoc CN Ha Noi", bankName: "IBK - Ha Noi" },
    IBKHCM: { bank: "NH Cong Nghiep Han Quoc CN TP.HCM", bankName: "IBK - TP.HCM" },
    WOO: { bank: "NH Wooribank", bankName: "Woori bank" },
    SEAB: { bank: "NH TMCP Dong Nam A", bankName: "SeABank" },
    UOB: { bank: "NH TNHH MTV United Overseas Bank", bankName: "UOB" },
    OCB: { bank: "NH TMCP Phuong Dong", bankName: "OCB" },
    MAFC: { bank: "Cong ty Tai chinh TNHH MTV Mirae Asset (Viet Nam)", bankName: "Mirae Asset" },
    KEBHANAHCM: { bank: "Ngan hang Keb Hana - Chi Nhanh TP Ho Chi Minh", bankName: "Keb Hana - Ho Chi Minh" },
    KEBHANAHN: { bank: "Ngan hang Keb Hana - Chi Nhanh Ha Noi", bankName: "Keb Hana - Ha Noi" },
    STANDARD: { bank: "Standard Chartered", bankName: "Standard Chartered" },
    CAKE: { bank: "NH So CAKE by VPBank", bankName: "CAKE" },
    Ubank: { bank: "NH So Ubank by VPBank", bankName: "Ubank" },
    NonghyupBankHN: { bank: "NH Nonghyup - Chi nhanh Ha Noi", bankName: "Nonghyup Bank - HN" },
    KBHN: { bank: "NH Kookmin - Chi nhanh Ha Noi", bankName: "Kookmin - HN" },
    KBHCM: { bank: "NH Kookmin - Chi nhanh TP. HCM", bankName: "Kookmin - HCM" },
    DBS: { bank: "NH DBS - Chi nhanh TP. HCM", bankName: "DBS - HCM" },
    CBB: { bank: "NH TM TNHH MTV Xay Dung Viet Nam", bankName: "CBBank" },
    KBankHCM: { bank: "NH Dai chung TNHH Kasikornbank - Chi nhanh TP. HCM", bankName: "KBank - HCM" },
    HSBC: { bank: "NH TNHH MTV HSBC Viet Nam", bankName: "HSBC" },
    TIMO: { bank: "NH So Timo", bankName: "Timo" }
};


export default AppConstant