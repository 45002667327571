import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import TagApi from "../api/tagApi";
import {REHYDRATE} from "redux-persist";

export const thunkGetAllTags = createAsyncThunk("tag/getAll", (params, thunkAPI) => {
    return TagApi.getAll(params)
})

export const thunkAddTag = createAsyncThunk("tag/add", (params, thunkAPI) => {
    return TagApi.addTag(params)
})

export const thunkDeleteTag = createAsyncThunk("tag/delete", (params, thunkAPI) => {
    return TagApi.deleteTag(params)
})

export const thunkUpdateTag = createAsyncThunk("tag/update", (params, thunkAPI) => {
    return TagApi.updateTag(params)
})

export const tagSlice = createSlice({
    name: "tag", initialState: {
        tags: [],
        currentTag: {
            tagId: 0,
            tagName: ""
        }

    },
    reducers: {
        setCurrentTag: (state, action) => {
            state.currentTag = action.payload
        }
    }
    , extraReducers: builder => {

        builder.addCase(REHYDRATE, (state, action) => {
            state.isAddingTag = false;
            state.isDeletingTag = false;
        });

        builder.addCase(thunkGetAllTags.fulfilled, (state, action) => {
            state.tags = action.payload.tags
        })

        builder.addCase(thunkAddTag.pending, (state, action) => {
            state.isAddingTag = true
        })

        builder.addCase(thunkAddTag.rejected, (state, action) => {
            state.isAddingTag = false
        })

        builder.addCase(thunkAddTag.fulfilled, (state, action) => {
            state.tags.push(action.payload.tag)
            state.isAddingTag = false
        })

        builder.addCase(thunkDeleteTag.pending, (state, action) => {
            state.isDeletingTag = true;
        })

        builder.addCase(thunkDeleteTag.rejected, (state, action) => {
            state.isDeletingTag = false;
        })

        builder.addCase(thunkDeleteTag.fulfilled, (state, action) => {
            state.isDeletingTag = false;
            state.tags = state.tags.filter(tag => tag.tagId !== action.payload.tagId)
        })

        builder.addCase(thunkUpdateTag.fulfilled, (state, action) => {
            state.tags = state.tags.map(tag => {
                if (tag.tagId === action.payload.tag.tagId) {
                    return action.payload.tag
                }
                return tag
            })
        })


    }
})
export const {reducer, actions} = tagSlice


export const {setCurrentTag} = actions

export default reducer