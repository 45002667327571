import React, {Fragment, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {Dialog, Transition} from "@headlessui/react";
import ReactPlayer from "react-player/lazy"

let showVideoModal, hideVideoModal;

function ModalVideo() {
    const [isOpen, setIsOpen] = useState(false);
    const [playing, setPlaying] = useState(true);
    const [source, setSource] = useState(null);

    useEffect(() => {
        showVideoModal = (content) => {
            setSource(content);
            setIsOpen(true);
        };

        hideVideoModal = () => {
            setSource(null);
            setIsOpen(false);
        };
    }, []);

    return ReactDOM.createPortal(
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50"
                onClose={() => {
                    hideVideoModal()
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25"/>
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div
                        className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="transform text-left align-middle transition-all">
                                <div className={"h-[90dvh] rounded-2xl overflow-hidden"}>
                                    <ReactPlayer
                                        style={{borderRadius: "1rem"}}
                                        url={source}
                                        config={{}} width={"100%"}
                                        height={"100%"}
                                        playing={playing}
                                        playIcon={
                                            <i className="fa-light fa-circle-play !text-pink-E3 z-10 text-2xl"></i>
                                        }
                                        controls={true}
                                    ></ReactPlayer>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>,
        document.body
    );
}

export {showVideoModal, hideVideoModal}

export default ModalVideo;
