import React, { createContext, useContext, useEffect, useCallback } from 'react';
import { WEBSOCKET_COMMAND } from "../general/constant/appConstant";
import PreferenceKey from "../general/constant/preferenceKey";
import { useSelector } from 'react-redux';

const WebSocketContext = createContext(null);

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
    const [socket, setSocket] = React.useState(null);
    const [isConnected, setIsConnected] = React.useState(false);
    const messageListeners = React.useRef(new Map());
    const { account } = useSelector(state => state.account);
    const {chatbot = {}} = useSelector(state => state.chatbot);
    const {chatbotId} = chatbot;

    const handleWebSocketMessage = useCallback((messageData) => {
        try {
            const data = JSON.parse(messageData);
    
            // Notify all listeners regardless of type
            messageListeners.current.forEach((listeners, type) => {
                listeners.forEach(callback => callback(data));
            });
        } catch (error) {
            console.error("Error parsing WebSocket message:", error);
        }
    }, []);
    

    const connect = useCallback(() => {
        const ws = new WebSocket(process.env.REACT_APP_URL_WEBSOCKET);

        ws.onopen = () => {
            console.log("WebSocket connected");
            setIsConnected(true);

            const accessToken = localStorage.getItem(PreferenceKey.accessToken);
            if (accessToken) {
                console.log("Sending token to WebSocket server");
                const tokenMessage = JSON.stringify({
                    command: WEBSOCKET_COMMAND.ACCOUNT_LOGIN,
                    accountId: account.accountId,
                    accessToken: accessToken,
                    chatbotId: chatbotId
                });
                ws.send(tokenMessage);
            }
        };

        ws.onclose = () => {
            console.log("WebSocket disconnected");
            setIsConnected(false);
            setTimeout(() => {
                connect();
            }, 3000);
        };

        ws.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        ws.onmessage = (event) => {
            handleWebSocketMessage(event.data); // Directly handle message
        };

        setSocket(ws);

        return () => {
            ws.close();
        };
    }, [account?.accountId, handleWebSocketMessage]);

    useEffect(() => {
        connect();
        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [connect]);

    const send = useCallback((message) => {
        console.log("Sending message:", message);
        if (socket?.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify(message));
        } else {
            console.error("WebSocket is not connected");
        }
    }, [socket]);

    const addEventListener = useCallback((type, callback) => {
        const key = type || 'global'; // Default to 'global' if no specific type
        if (!messageListeners.current.has(key)) {
            messageListeners.current.set(key, new Set());
        }
        messageListeners.current.get(key).add(callback);
    
        return () => {
            const listeners = messageListeners.current.get(key);
            if (listeners) {
                listeners.delete(callback);
                if (listeners.size === 0) {
                    messageListeners.current.delete(key);
                }
            }
        };
    }, []);
    

    const updateChatbotId = useCallback((chatbotId) => {
        send({
            type: 'update_chatbot_id',
            chatbotId: chatbotId,
        });
    }, [send]);

    // const updateToken = useCallback((accessToken, accountId) => {
    //     send({
    //         command: WEBSOCKET_COMMAND.ACCOUNT_LOGIN,
    //         accountId: accountId || account.accountId,
    //         accessToken: accessToken,
    //     });
    // }, [send, account?.accountId]);

    const removeToken = useCallback(() => {
        send({
            type: 'remove_token',
        });
    }, [send]);

    const value = {
        socket,
        isConnected,
        send,
        addEventListener,
        updateChatbotId,
        removeToken
    };

    return (
        <WebSocketContext.Provider value={value}>
            {children}
        </WebSocketContext.Provider>
    );
};
