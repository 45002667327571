import vi from "dayjs/locale/vi";
import AxiosClient from "./axiosClient";

const ChatbotApi = {
    addChatbot: (data) => {
        const url = "/chatbot/add"
        const bodyFormData = new FormData()
        for (const [key, value] of Object.entries(data)) {
            bodyFormData.append(key, value)
        }
        return AxiosClient({
            method: "post",
            url,
            headers: {"Content-Type": "multipart/form-data"},
            data: bodyFormData
        })

    },
    updateChatbot: (data) => {
        const url = "/chatbot/update"
        const bodyFormData = new FormData()
        for (const [key, value] of Object.entries(data)) {
            bodyFormData.append(key, value)
        }
        return AxiosClient({
            method: "put",
            url,
            headers: {"Content-Type": "multipart/form-data"},
            data: bodyFormData
        })

    },
    getInformation: (params) => {
        const url = "/chatbot/information"
        return AxiosClient.get(url, {params})
    },
    getAll: (params) => {
        const url = "/chatbot/all"
        return AxiosClient.get(url, {params})
    },
    subscribePlan: (params) => {
        const url = "/chatbot/subscribe-plan"
        return AxiosClient.post(url, params)
    },
    deleteChatbots: (params) => {
        const url = "/chatbot/delete"
        return AxiosClient.post(url, params)
    },
    getKPI: (params) => {
        const url = "/chatbot/kpi"
        return AxiosClient.get(url, {params})
    },
    summarize: (params) => {
        const url = "/chatbot/summarize"
        return AxiosClient.get(url, {params})
    },
    viewSummarize: (params) => {
        const url = "/chatbot/view-summarize"
        return AxiosClient.get(url, {params})
    },
    summarizeReport: (params) => {
        const url = "/chatbot/summarize-report"
        return AxiosClient.get(url, {params, responseType: 'arraybuffer'})
    },
    summarizeReportBrief: (params) => {
        const url = "/chatbot/summarize-report-brief"
        return AxiosClient.get(url, {params, responseType: 'arraybuffer'})
    },
    kpiReport: (params) => {
        const url = "/chatbot/kpi-report"
        return AxiosClient.get(url, {params, responseType: 'arraybuffer'})
    },
    viewCustomerReport: (params) => {
        const url = "/chatbot/view-customer-report"
        return AxiosClient.get(url, {params})
    },
    customerReport: (params) => {
        const url = "/chatbot/customer-report"
        return AxiosClient.get(url, {params, responseType: 'arraybuffer'})
    },
    viewConversationReport: (params) => {
        const url = "/chatbot/view-conversation-report"
        return AxiosClient.get(url, {params})
    },
    conversationReport: (params) => {
        const url = "/chatbot/conversation-report"
        return AxiosClient.get(url, {params, responseType: 'arraybuffer'})
    },
    viewMessageReport: (params) => {
        const url = "/chatbot/view-message-report"
        return AxiosClient.get(url, {params})
    },
    messageReport: (params) => {
        const url = "/chatbot/message-report"
        return AxiosClient.get(url, {params, responseType: 'arraybuffer'})
    },
    viewTestReport: (params) => {
        const url = "/chatbot/view-test-report"
        return AxiosClient.get(url, {params})
    },
    testReport: (params) => {
        const url = "/chatbot/test-report"
        return AxiosClient.get(url, {params, responseType: 'arraybuffer'})
    },
    dailyTestReport: (params) => {
        const url = "/chatbot/daily-test-report"
        return AxiosClient.get(url, {params, responseType: 'arraybuffer'})
    },
    viewLoginReport: (params) => {
        const url = "/chatbot/view-login-report"
        return AxiosClient.get(url, {params})
    },
    loginReport: (params) => {
        const url = "/chatbot/login-report"
        return AxiosClient.get(url, {params, responseType: 'arraybuffer'})
    },
    viewMofiiedMessageReport: (params) => {
        const url = "/chatbot/view-message-modified-report"
        return AxiosClient.get(url, {params})
    },
    modifiedMessageReport: (params) => {
        const url = "/chatbot/message-modified-report"
        return AxiosClient.get(url, {params, responseType: 'arraybuffer'})
    },
    viewConversationFromTesterReport: (params) => {
        const url = "/chatbot/view-conversation-from-tester-report"
        return AxiosClient.get(url, {params})
    },
    conversationFromTesterReport: (params) => {
        const url = "/chatbot/conversation-from-tester-report"
        return AxiosClient.get(url, {params, responseType: 'arraybuffer'})
    },
}

export default ChatbotApi