import { router } from "./general/route";
import { RouterProvider } from 'react-router-dom'
import { persistor, store } from "./app/store";
import { Provider } from "react-redux";
import { enUS, vi } from "date-fns/locale"
import { AppToast } from "./general/component/toast";
import 'react-toastify/dist/ReactToastify.css';
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-quartz.css"
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { setDefaultOptions } from "date-fns";
import { PersistGate } from "redux-persist/integration/react"
import "swiper/swiper-bundle.css"
import { I18nextProvider, useTranslation } from "react-i18next";
import { useEffect } from "react";
import { onMessage } from "firebase/messaging"
import i18 from "./i18n"
import "./App.css"
import "./asset/font_awesome_6_pro/css/all.css"
import {
    NOTIFICATION_PERMISSION_STATUS,
    SOCKET_CONFIG,
    TOAST_CONFIG
} from "./general/constant/appConstant";
import { messaging } from "./firebase-config"
import { ModalImage, ModalVideo } from "./general/component/modal";
// import SocketIoClient from "./socketio/socketIoClient";
import toastHelper from "./general/helper/toastHelper";
import { LicenseInfo } from "@mui/x-license"
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider"
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import "dayjs/locale/vi"
import { WebSocketProvider } from "./context/websocketContext";
import SocketIoClient from "./socketio/socketIoClient";

LicenseInfo.setLicenseKey("e0d9bb8070ce0054c9d9ecb6e82cb58fTz0wLEU9MzI0NzIxNDQwMDAwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y")

function App() {
    const { i18n, t } = useTranslation()
    useEffect(() => {
        switch (i18n.language) {
            case "en":
                setDefaultOptions({ locale: enUS })
                break;
            case "vi":
                setDefaultOptions({ locale: vi })
                break;
            default:
                setDefaultOptions({ locale: vi })
                break;
        }
    }, [i18n])

    useEffect(() => {
        onMessage(messaging, (payload) => {
            const { title, body, image } = payload.notification;
            if (Notification.permission === NOTIFICATION_PERMISSION_STATUS.granted) {
                navigator.serviceWorker.getRegistration().then((registration) => {
                    registration.showNotification(title, {
                        icon: image, body
                    }).then()
                })
            }
        });
    }, []);

    useEffect(() => {
        SocketIoClient.io.on("reconnect_attempt", attempt => {
            if (attempt === SOCKET_CONFIG.ATTEMPT) {
                toastHelper.showWarningDisconnect(t("you_are_disconnect"))
            }
        })
        SocketIoClient.io.on("reconnect", attempt => {
            toastHelper.closeToast(TOAST_CONFIG.SOCKET_DISCONNECT_ID)
        })


        return () => {
            SocketIoClient.io.off("reconnect_attempt")
            SocketIoClient.io.off("reconnect")
        }
    })

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <WebSocketProvider>
                    <I18nextProvider i18n={i18}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"vi"}>
                            <RouterProvider router={router}>
                            </RouterProvider>
                            <AppToast></AppToast>
                            <ModalImage></ModalImage>
                            <ModalVideo></ModalVideo>
                        </LocalizationProvider>
                    </I18nextProvider>
                </WebSocketProvider>
            </PersistGate>
        </Provider>
    );
}

export default App;
